body {
  background: rgb(111, 167, 199);
  background: linear-gradient(127deg, rgba(111, 167, 199, 1) 0%, rgba(79, 127, 162, 1) 20%, rgba(38, 79, 114, 1) 70%, rgba(223, 84, 62, 1) 100%);
}

.shadowContainer {
  -webkit-box-shadow: 0px 0px 15px -1px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 15px -1px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -1px rgba(0, 0, 0, 0.75);
}

.shadowFluid {
  -webkit-box-shadow: 0px 0px 15px -1px rgba(223, 84, 62, 0.75);
  -moz-box-shadow: 0px 0px 15px -1px rgba(223, 84, 62, 0.75);
  box-shadow: 0px 0px 15px -1px rgba(223, 84, 62, 0.75);
}

.devaez {
  text-decoration: none;
  color: black;
  font-size: small;
}